@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,500;1,500&display=swap);
body {
  margin: 0;
  font-family: 'Roboto' !important;
  scrollbar-width: thin !important;
  background-color: #2B3648;
  color: #AFBDD1 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px !important;
  background-color: #313c4e !important; }

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
margin: 0; 
}
input[type=number] { -moz-appearance:textfield; }
